import { Injectable, OnDestroy } from '@angular/core';
import { Params, Router } from '@angular/router';
import { from, Observable, of, Subject } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

import { finishedOnly } from 'src/app/utils/rxjs-operators';

import { ApiService } from './api.service';
import { ApiResponse } from './http.service';
import { JobTabNames } from '../enum';

@Injectable({
  providedIn: 'root'
})
export class IframeService implements OnDestroy {
  private destroy$ = new Subject<void>();

  /* iframe/ apis are called in Saleforce and Bullhorn when the iframe is rendered
    once the iframe/ api call is successful, Angular routes to the specified route with query params that equate to what the iframe/ api returned

    the query params determine what data shows in the route that Angular routes to
  */

  private jobPosting = (route: string, params: Params) =>
    this.api
      .get<JobPostingResponse>({
        path: 'iframe/job-posting',
        params
      })
      .pipe(
        finishedOnly(),
        switchMap(result => {
          if (result.success) {
            return this.checkResponse(route, params, result.response, () =>
              this.router.navigate(['job', result.data.jobId, JobTabNames.SETUP], { queryParams: { iframeSource: params.source, iframeRoute: route, t: 1 } })
            );
          }

          return of(false);
        }),
        takeUntil(this.destroy$)
      );

  private jobsMatched = (route: string, params: Params) =>
    this.api
      .get<CandidateResponse>({
        path: 'iframe/jobs-matched',
        params
      })
      .pipe(
        finishedOnly(),
        switchMap(result => {
          if (result.success) {
            return this.checkResponse(route, params, result.response, () =>
              this.router.navigate(['candidate', 'matches'], { queryParams: { profileRef: result.data.profileRef, iframeSource: params.source, iframeRoute: route } })
            );
          }

          return of(false);
        }),
        takeUntil(this.destroy$)
      );

  private searchCandidates = (route: string, params: Params) =>
    this.api
      .get<JobPostingResponse>({
        path: 'iframe/search-candidates',
        params
      })
      .pipe(
        finishedOnly(),
        switchMap(result => {
          if (result.success) {
            return this.checkResponse(route, params, result.response, () =>
              this.router.navigate(['job', result.data.jobId, JobTabNames.SETUP], { queryParams: { iframeSource: params.source, iframeRoute: route, t: 2 } })
            );
          }

          return of(false);
        }),
        takeUntil(this.destroy$)
      );

  private resume = (route: string, params: Params) =>
    this.api
      .get<CandidateResponse>({
        path: 'iframe/resume',
        params
      })
      .pipe(
        finishedOnly(),
        switchMap(result => {
          if (result.success) {
            return this.checkResponse(route, params, result.response, () =>
              this.router.navigate(['candidate', 'resume'], { queryParams: { profileRef: result.data.profileRef, iframeSource: params.source, iframeRoute: route } })
            );
          }

          return of(false);
        }),
        takeUntil(this.destroy$)
      );

  private checkResponse(route: string, params: Params, response: ApiResponse<any>, navigate: () => Promise<boolean>) {
    if (response.status.code === 1 || !response.data?.sessionId) {
      return of(false);
    } else if (response.data) {
      if (this.isInIframe) {
        document.documentElement.classList.add('iframe');

        if (params.source) {
          document.documentElement.classList.add(params.source);
        }

        if (route) {
          document.documentElement.classList.add(route);
        }
      }

      return from(navigate());
    } else {
      return of(true);
    }
  }

  routes: Record<string, (route: string, params: Params) => Observable<boolean>> = {
    job: this.jobPosting, // salesforce and bullhorn
    'jobs-matched': this.jobsMatched, // bullhorn
    resume: this.resume, // salesforce
    'search-candidates': this.searchCandidates
  };

  get isInIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  constructor(
    private router: Router,

    private api: ApiService
  ) {}

  processRoute(routeString: string, params: Params) {
    const route = this.routes[routeString];

    params.sourceId = (
      {
        salesforce: 1009,
        bullhorn: 1013
      } as any
    )[params.source];

    if (route) {
      return route(routeString, params).pipe(takeUntil(this.destroy$));
    } else {
      return of(false);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

interface JobPostingResponse {
  jobId: number;
  sessionId: string;
}

interface CandidateResponse {
  profileRef: string;
  sessionId: string;
}
