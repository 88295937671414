<div class="w-100 d-flex justify-content-between align-items-center mat-small">
  <div class="d-flex align-items-center" style="min-width: 380px;">
    <div class="d-flex align-items-center customBtn" style="margin-right: 26px" *ngIf="pagination && pagination.maxPages > 1">
      <ng-container
        *ngTemplateOutlet="
          button;
          context: {
            icon: 'keyboard_arrow_left',
            incr: -1,
            disabled: pageIndex === 0
          }
        ">
      </ng-container>
  
      <ng-container *ngFor="let page of pagination.pages; trackBy: trackByFn">
        <button mat-raised-button class="ms-2" [ngClass]="page === pagination.index + 1 ? 'mat-primary' : 'mat-basic'" (click)="navigateToPage(page - 1)">
          {{ page }}
        </button>
      </ng-container>
  
      <ng-container
        *ngTemplateOutlet="
          button;
          context: {
            icon: 'keyboard_arrow_right',
            incr: 1,
            disabled: length !== null && (pageIndex + 1) * pageSize >= length
          }
        ">
      </ng-container>
    </div>
  
    <div class="d-flex" *ngIf="pagination && pagination.maxPages > 1">
      <div class="d-flex align-items-center body-md">Jump to:</div>
      <div class="d-flex ms-2 custom-height" style="min-width: 60px">
        <select class="form-select" [ngModel]="pageIndex + ''" (ngModelChange)="jumpTo($event)">
          <option *ngFor="let page of pagesList" [ngValue]="page - 1 + ''">
            {{ page }}
          </option>
        </select>
      </div>
    </div>
  </div>

  <div class="d-flex align-items-center custom-heigh customBtn" *ngIf="priorityId">
    <mat-button-toggle-group class="custom-toggle" [ngModel]="priorityId + ''" (ngModelChange)="changePrority($event)">
      <mat-button-toggle value="1000" >Visible</mat-button-toggle>
      <mat-button-toggle value="6000" >Hidden</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="d-flex align-items-center" style="min-width: 400px">
    <div class="d-flex" *ngIf="length > 0">
      <div class="d-flex align-items-center body-md">Entries per page:</div>
      <div class="d-flex ms-2 custom-height" style="min-width: 60px">
        <select class="form-select" [ngModel]="pageSize + ''" (ngModelChange)="changePageSize($event)">
          <option [value]="25">25</option>
          <option [value]="50">50</option>
          <option [value]="75">75</option>
          <option [value]="100">100</option>
        </select>
      </div>
    </div>
    
    <div class="d-flex body-md" style="margin-left: 16px">
      Showing
      {{ Math.min(pageIndex * pageSize + 1, length) | number : '1.0' : 'en-US' }}
      <span class="mx-1">–</span>
      {{ Math.min((pageIndex + 1) * pageSize, length) | number : '1.0' : 'en-US' }}
      <ng-container *ngIf="length !== null">
        <span class="mx-1">of</span>
        {{ length | number : '1.0' : 'en-US' }} entries
      </ng-container>
    </div>
  </div>
</div>

<ng-template #button let-icon="icon" let-incr="incr" let-disabled="disabled">
  <button
    mat-icon-button
    [ngClass]="{
    'mat-color-gray-dark' : !disabled,
    'me-2': icon === 'keyboard_arrow_left',
    'ms-3': icon === 'keyboard_arrow_right',
  }"
    (click)="incrementPage(incr)"
    [disabled]="disabled">
    <mat-icon class="md-icon-20">{{ icon }}</mat-icon>
  </button>
</ng-template>
