<ng-container *ngIf="candidate$ | async as candidate">
  <ng-container *ngIf="{ result: caseInsights$ | async } as caseInsights">
    <ng-container
      *ngIf="
        {
          overview: overview$ | async,
          jobsMatched: jobsMatched$ | async,
          ruleGroups: ruleGroups$ | async
        } | apiResultsMerge as results
      ">
      <div style="width: 30vw" fxLayout="column" fxFlex="grow">
        <div class="p-3 mat-background-primary mat-color-primary-contrast" fxLayout="row">
          <button mat-icon-button (click)="closeSidebar()">
            <mat-icon>arrow_forward</mat-icon>
          </button>

          <h3 class="ps-2 m-0 w-0 ellipsis" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="grow">
            {{ candidate?.displayName }}
          </h3>

          <button mat-icon-button (click)="prev()" *ngIf="showPrev">
            <mat-icon>navigate_before</mat-icon>
          </button>

          <button mat-icon-button (click)="next()" [class.invisible]="!showNext">
            <mat-icon>navigate_next</mat-icon>
          </button>

          <app-pr-candidate-menu [items]="[candidate] | truthify"> </app-pr-candidate-menu>
        </div>

        <mat-divider></mat-divider>

        <div class="h-0 scroll-y p-relative" fxFlex="grow">
          <div class="full-shade" *ngIf="!results.finished">
            <mat-spinner color="accent"></mat-spinner>
          </div>

          <div class="h-100" [style.display]="!results.finished ? 'none' : undefined">
            <ng-container *ngTemplateOutlet="controlTemplate"> </ng-container>
          </div>
        </div>
      </div>

      <ng-template #controlTemplate>
        <mat-tab-group class="h-100" [selectedIndex]="0" *ngIf="results.success">
          <mat-tab label="OVERVIEW">
            <ng-container *ngIf="results.data.overview.data.imgUrl">
              <div class="p-3" fxLayout="row" fxLayoutAlign="center center">
                <app-user-avatar class="mx-2" [src]="results.data.overview.data.imgUrl" size="200px"> </app-user-avatar>
              </div>

              <mat-divider></mat-divider>
            </ng-container>

            <ng-container *ngFor="let item of results.data.overview.data.contacts">
              <ng-container *ngIf="item.contactValue">
                <div class="p-3 hover-row" fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon *ngIf="item.contactType === 'Mobile'">call</mat-icon>
                  <mat-icon *ngIf="item.contactType === 'Telephone'">call</mat-icon>
                  <mat-icon *ngIf="item.contactType === 'Email'">email</mat-icon>

                  <div class="w-100" fxLayout="row" fxFlex="grow" fxLayoutAlign="space-between center">
                    <div class="px-3 w-0" fxFlex="grow" fxLayout="column">
                      <span class="my-0 ellipsis" fxFlex="grow">
                        {{ item.contactValue | formatContactInfo : item.contactType }}
                      </span>
                      <span class="hint mat-small ellipsis" fxFlex="grow">{{ item.contactType }}</span>
                    </div>
                  </div>
                </div>

                <mat-divider></mat-divider>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="results.data.overview.data.priorJobs.length > 0">
              <h3 class="p-3 m-0">Experience</h3>

              <ng-container *ngFor="let item of results.data.overview.data.priorJobs">
                <div class="p-3 hover-row" fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon>work</mat-icon>

                  <div class="w-100" fxLayout="row" fxFlex="grow" fxLayoutAlign="space-between center">
                    <div class="px-3 w-0" fxFlex="grow" fxLayout="column">
                      <span class="my-0 ellipsis" fxFlex="grow">{{ item.title }}</span>
                      <span class="hint mat-small ellipsis" fxFlex="grow">{{ item.companyName }}</span>
                    </div>

                    <span class="hint mat-small no-wrap" fxLayout="row">{{ item.servicePeriod }} years</span>
                  </div>
                </div>

                <mat-divider></mat-divider>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="results.data.overview.data.education.length > 0">
              <h3 class="p-3 m-0">Education</h3>

              <ng-container *ngFor="let item of results.data.overview.data.education">
                <div class="p-3 hover-row" fxLayout="row" fxLayoutAlign="start center">
                  <mat-icon>school</mat-icon>

                  <div class="w-100" fxLayout="row" fxFlex="grow" fxLayoutAlign="space-between center">
                    <div class="px-3 w-0" fxFlex="grow" fxLayout="column">
                      <span class="my-0 ellipsis" fxFlex="grow">{{ item.schoolName }}</span>
                      <span class="hint mat-small ellipsis" fxFlex="grow">{{ item.degreeName }}</span>
                    </div>
                  </div>
                </div>

                <mat-divider></mat-divider>
              </ng-container>
            </ng-container>
          </mat-tab>

          <mat-tab label="CASE INSIGHTS" *ngIf="caseInsights.result && caseInsights.result.success">
            <ng-container *ngFor="let ruleGroup of results.data.ruleGroups.data">
              <div class="p-3 mat-small" fxLayout="column">
                <h3 class="m-0 mb-2">{{ ruleGroup.ruleGroupDesc }} ({{ ruleGroup.ruleGroupShort }})</h3>

                <ng-container *ngIf="caseInsights.result.data[ruleGroup.apiKey].hasInfo === 0"> The candidates were not evaluated with regard to these criteria. </ng-container>

                <ng-container *ngIf="caseInsights.result.data[ruleGroup.apiKey].hasInfo === 1">
                  <span class="mb-1 body"> {{ caseInsights.result.data[ruleGroup.apiKey].score }}% ({{ caseInsights.result.data[ruleGroup.apiKey].scoreText }}) </span>

                  <ng-container *ngIf="caseInsights.result.data[ruleGroup.apiKey].insights as insights">
                    <ng-container *ngIf="insights.length > 0">
                      <span class="my-1">Additional Insights</span>

                      <table>
                        <tr *ngFor="let insight of insights">
                          <td>
                            <b class="no-wrap">{{ insight.title }}:</b>
                          </td>
                          <td>
                            <span>{{ insight.value }}</span>
                          </td>
                        </tr>
                      </table>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>

              <mat-divider></mat-divider>
            </ng-container>
          </mat-tab>

          <mat-tab label="JOBS MATCHED">
            <ng-container *ngFor="let item of results.data.jobsMatched.data">
              <div class="p-3 hover-row c-pointer" fxLayout="row" fxLayoutAlign="start center" (click)="jobPostingLink.click()">
                <mat-icon>work</mat-icon>

                <div class="w-100" fxLayout="row" fxFlex="grow" fxLayoutAlign="space-between center">
                  <div class="px-3 w-0" fxFlex="grow" fxLayout="column">
                    <span class="my-0 ellipsis" fxFlex="grow">{{ item.jobTitle }}</span>
                  </div>

                  <span class="hint mat-small no-wrap" fxLayout="row">{{ item.proScore }}</span>
                </div>
              </div>

              <a #jobPostingLink class="d-none" [routerLink]="['/job', item.jobId, 'pipeline', PipelineTabNames.SEARCH]" target="_blank"></a>

              <mat-divider></mat-divider>
            </ng-container>
          </mat-tab>

          <mat-tab label="NOTES">
            <app-pr-notes-list [header]="false" [profileRef]="candidate.profileRef"> </app-pr-notes-list>
          </mat-tab>

          <mat-tab label="TASKS">
            <app-pr-tasks-list [header]="false" [profileRef]="candidate.profileRef"> </app-pr-tasks-list>
          </mat-tab>
        </mat-tab-group>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
