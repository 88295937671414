import { Component, Inject, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, of, share, switchMap, take, takeUntil } from 'rxjs';
import { NoteBody, NoteItem, NotesService } from 'src/app/services/api/notes.service';
import { XrefService } from 'src/app/services/api/xref.service';
import { ApiService, DataTable } from 'src/app/services/api.service';
import { mapSuccessData, successData } from 'src/app/utils/rxjs-operators';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { JobPostingHeader } from 'src/app/services/api/job-postings.service';
import { CandidatesService } from 'src/app/services/api/candidates.service';
import { CompaniesService } from 'src/app/services/api/companies.service';
import { ContextService } from 'src/app/services/context.service';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddNoteComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();


  note?: NoteItem;
  editMode: any;
  candidate: any;

  jobs: any[] = [];

  addNoteForm = this.fb.group({
    title: new FormControl<string>('', Validators.required),
    note: new FormControl<string>('', Validators.required),
    markConfidential: new FormControl<boolean>(false, Validators.required),
    noteTypeId: new FormControl<any>(null, Validators.required),
    companyId: new FormControl<any>(null),
    profileRef: new FormControl<any>(null)
  }
  );

  controlTypes = {


    jobs: {
      name: 'Job',
      xref: () => (searchValue?: string) => of(null).pipe(
        switchMap(() => this.api.post<DataTable<JobPostingHeader>>({
          path: 'job-postings',
          body: (searchValue || '') ? { searchValue, viewId: 1000 } : { viewId: 1000 }
        })),
        mapSuccessData(data => data.dataTable),
        share(),
        takeUntil(this.destroy$)
      ),
      valueKey: 'jobId',
      textKey: 'jobTitle'
    } as any

  };


  notesTypes$ = this.xref.getNotesTypes().pipe(
    successData(),
    takeUntil(this.destroy$)
  );
  searchCandidate = (searchValue?: string) => this.candidatesService.search(searchValue).pipe(
    mapSuccessData(data => data.map(x => ({ value: x.profileRef.toString(), text: x.displayName }))),
    takeUntil(this.destroy$)
  );

  searchCompany = (searchValue?: string) => this.companiesService.searchCompanies(searchValue).pipe(
    mapSuccessData(data => data.dataTable.map(x => ({ value: x.companyId.toString(), text: x.companyName }))),
    takeUntil(this.destroy$)
  );

  companyText? = '';
  candidateText? = '';
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private _ngZone: NgZone,
    private noteService: NotesService,

    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<AddNoteComponent>,
    private api: ApiService,
    private refreshService: RefreshService,
    private xref: XrefService,
    private candidatesService: CandidatesService,
    private companiesService: CompaniesService,
    public context: ContextService

  ) {

  }

  ngOnInit() {


    this.candidate = this.data.candidate;
    this.note = this.data.note;
    this.editMode = this.data.editMode;


    // popup is opened in adding mode 
    if (!this.editMode) {

      this.addNoteForm.patchValue({
        noteTypeId: 1000 + '',
        profileRef: this.data.candidate?.profileRef,
        companyId: this.context.latest?.companyId
      });

      this.jobs.push({ jobId: this.context.latest?.jobId, jobTitle: this.context.latest?.jobTitle });
      this.candidateText = this.data.candidate.displayName;
      this.companyText = this.context.latest?.companyName;
    }
    else {


      this.noteService.getNoteJobs(this.note?.noteId).pipe(takeUntil(this.destroy$)).subscribe(
        noteJobs => {

          this.addNoteForm.patchValue({
            title: this.note?.noteTitle,
            note: this.note?.note,
            noteTypeId: this.note?.noteTypeId + '',
            markConfidential: this.note?.isConfidential === 1,
            profileRef: this.note?.profileRef,
            companyId: this.note?.companyId
          });
          this.jobs = noteJobs;
          this.candidateText = this.note?.candidateDisplayName;
          this.companyText = this.note?.companyName;
        }
      );
    }

  }

  addNewNote() {

    const body: NoteBody = {

      noteTitle: this.addNoteForm.value.title,
      note: this.addNoteForm.value.note,
      noteTypeId: Number(this.addNoteForm.value.noteTypeId),
      isActive: 1,
      taskDueDateEpoch: '',
      noteId: this.note?.noteId,
      isConfidential: this.addNoteForm.value.markConfidential ? 1 : 0,
      companyId: Number(this.addNoteForm.value.companyId),
      jobs: this.jobs.map(x => Number(x.jobId)),
      profileRef: this.addNoteForm.value.profileRef

    };

    this.noteService.saveNote(body).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.refreshService.setRefreshStagesObs(true);
      this.dialogRef.close();
    });

  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  @ViewChild('autosize') autosize?: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => this.autosize?.resizeToFitContent(true));

  }

  close() {

    this.dialogRef.close();
  }


  select(control: string, $event: any) {

    this.addNoteForm.get(control)?.setValue($event);
  }
}
