import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ApiService } from './../api.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService implements OnDestroy {
  private destroy$ = new Subject<void>();

  get$ = this.api.get<Employee[]>({
    path: 'recruiter/members'
  }).pipe(
    takeUntil(this.destroy$)
  );

  search = (searchValue?: string) => this.api.get<Employee[]>({
    path: 'recruiter/members', cacheDuration: -1, //////////////
    params: { searchValue }
  }).pipe(
    takeUntil(this.destroy$)
  );

  save = (user: Employee) => this.api.post<Employee[]>({
    path: 'recruiter/members',
    body: { userId: user.userId, isActive: user.isActive }
  }).pipe(
    takeUntil(this.destroy$)
  );

  saveProducts = (user: Employee) => this.api.post<Employee[]>({
    path: 'recruiter/members',
    body: { userId: user.userId, products: user.products }
  }).pipe(
    takeUntil(this.destroy$)
  );

  saveRoles = (user: Employee) => this.api.post<Employee[]>({
    path: 'recruiter/members',
    body: { userId: user.userId, roles: user.roles }
  }).pipe(
    takeUntil(this.destroy$)
  );

  getUsersAssignedToJobs = (searchCriteria: any) => this.api.post<any>({
    path: 'recruiter/members/assigned',
    body: searchCriteria
  }).pipe(
    takeUntil(this.destroy$)
  );

  constructor(
    private api: ApiService
  ) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export interface Employee {
  userId: number;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  nameDisplay: string;
  roles: string[];
  products: string[];
  isAdmin: 0 | 1;
  isActive: 0 | 1;
  hasSignedIn: 0 | 1;
}

export interface RoleType {
  userRoleId: number;
  roleName: string;
  key: string;
}

export interface SeatType {
  productId: number;
  productName: string;
  key: string;
}